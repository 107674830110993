<template>
  <d-modal
    :modal-id="`confirm-${data.target}-id`"
    :title="data.title"
    :text="data.text"
    :hide="data.hide"
    :display="data.display"
    @on:cancel="$emit(`on:${data.target}:cancel`)"
    @on:confirm="$emit(`on:${data.target}:confirm`)"
  />
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  }
}
</script>
